@media (max-width: 480px) {
    .wrapper_all_products {
        padding: 4vh 0;

        .qwe {
            width: 100%;
            padding-bottom: 5vh;
            span {
                width: 90%;
                font-size: 2.5rem;
                text-align: center;
                font-family: "Barlow-Bold" ;
            }
        }

        .all_products {
            width: 90%;

            .product {
                flex-direction: column;
                gap: 8vh;
            }
        }
        .card {
            width: 280px;
            max-height: 500px;

            .image_section {
                width: 100%;
            }

            .card_footer {
                width: 100%;
                height: auto;
                gap: 1vh;
                padding: 2vh 0;

                .item1 {
                    font-size: 1.5rem;
                }

                .item2 {
                    font-size: 1.25rem;
                }

                .btn-18 {
                    display: none;
                }

                .button_media{
                    display: block;
                    width: 180px;
                    height: 40px;

                    color: white;
                    font-size: 1.2rem;
                    font-family: "Barlow-Bold";
                    text-transform: uppercase;

                    background: rgb(0, 102, 255);
                    border: 2px solid rgb(0, 102, 255);
                    border-radius: 8px;

                    &:hover{
                        background: white;
                        color: rgb(0, 102, 255);
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_all_products {
        padding: 4vh 0;

        .qwe {
            width: 100%;
            padding-bottom: 3vh;
            span {
                width: 80%;
                font-size: 2.7rem;
                text-align: center;
                font-family: "Barlow-Bold" ;
            }
        }

        .all_products {
            width: 90%;

            .product {
                flex-wrap: wrap;
                gap: 3vh;
            }
        }
        .card {
            width: 320px;
            // height: 450px;
            height: auto;

            .image_section {
                width: 100%;
            }

            .card_footer {
                width: 100%;
                height: auto;
                gap: 1vh;
                padding-bottom: 1.8vh;

                .item1 {
                    font-size: 1.6rem;
                }

                .item2 {
                    font-size: 1.3rem;
                }

                .btn-18 {
                    display: none;
                }

                .button_media{
                    display: block;
                    width: 200px;
                    height: 45px;

                    color: white;
                    font-size: 1.3rem;
                    font-family: "Barlow-Bold";
                    text-transform: uppercase;

                    background: rgb(0, 102, 255);
                    border: 2px solid rgb(0, 102, 255);
                    border-radius: 8px;

                    &:hover{
                        background: white;
                        color: rgb(0, 102, 255);
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_all_products {
        padding: 4vh 0;

        .qwe {
            width: 100%;
            padding-bottom: 3vh;
            span {
                width: 80%;
                font-size: 2.7rem;
                text-align: center;
                font-family: "Barlow-Bold" ;
            }
        }

        .all_products {
            width: 90%;

            .product {
                width: 100%;
                flex-wrap: wrap;
                gap: 3vh;
                justify-content: flex-start;
            }
        }
        .card {
            width: 320px;
            // height: 450px;
            height: auto;

            .image_section {
                width: 100%;
            }

            .card_footer {
                width: 100%;
                height: auto;
                gap: 1vh;
                padding-bottom: 1.8vh;

                .item1 {
                    font-size: 1.6rem;
                }

                .item2 {
                    font-size: 1.3rem;
                }

                .btn-18 {
                    display: none;
                }

                .button_media{
                    display: block;
                    width: 200px;
                    height: 45px;

                    color: white;
                    font-size: 1.3rem;
                    font-family: "Barlow-Bold";
                    text-transform: uppercase;

                    background: rgb(0, 102, 255);
                    border: 2px solid rgb(0, 102, 255);
                    border-radius: 8px;

                    
                    &:hover{
                        background: white;
                        color: rgb(0, 102, 255);
                    }
                }
            }
        }
    }
}

