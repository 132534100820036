.btn-add {
    border: none;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    background: #0B63F6;
    box-shadow: 0 5px 0 #003CC5;
    padding: .4em 3.5rem;
    font-size: 1.4em;
}

.btn-add:hover {
    background: #003CC5;
    box-shadow: none;
    position: relative;
    top: 5px;
}