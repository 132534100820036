.wrapper_solution{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255, 245, 232);
    padding: 8vh 0;
}

.solution{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 2vw;
    color: rgb(0, 0, 0);

    b{
        color: rgb(0, 0, 0);
    }
    
    .main_item{
        font-size: 2.5em;
        text-align: center;
        font-weight: 800;
        color: black;
        padding-bottom: 2vh;
        width: 70%;
    }
    
    .item{
        width: 60%;
        font-size: 1.2em;
        text-align: center;
        word-spacing: 5px;
        letter-spacing: 1px;
    }
}

