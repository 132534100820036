@media (max-width: 480px) {
    .wrapper_solution {
        padding: 5vh 0;
    }

    .solution {
        width: 100%;

        .main_item {
            width: 90%;
            font-size: 2rem;
            font-family: "Barlow-Bold";
        }

        .item {
            width: 90%;
            font-size: 1.2rem;
            color: rgb(0, 0, 0);
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_solution {
        padding: 5vh 0;
    }

    .solution {
        width: 100%;

        .main_item {
            width: 90%;
            font-size: 2.7rem;
            font-family: "Barlow-Bold";
        }

        .item {
            width: 80%;
            font-size: 1.4rem;
            color: rgb(0, 0, 0);
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_solution {
        padding: 5vh 0;
    }

    .solution {
        width: 100%;

        .main_item {
            width: 80%;
            font-size: 2.7rem;
            font-family: "Barlow-Bold";
        }

        .item {
            width: 80%;
            font-size: 1.4rem;
            color: rgb(0, 0, 0);
        }
    }
}

