.cart_modal_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.483);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    margin: 0 auto;
}

.cart_modal {
    background: rgb(255, 245, 232);
    width: 45%;
    height: auto;
    padding: 20px;
    box-shadow: 1px 1px 15px 1px rgba(255, 255, 255, 0.178);
    position: relative;
    // border-top-left-radius: 15px;
    // border-bottom-left-radius: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}



.close_button {
    background: transparent;
    color: black;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 1.2em;

    img {
        width: 25px;
        height: 25px;
    }
}
.cart_name {
    width: 100%;
    height: 50px;
    padding: 5px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgb(85, 85, 85);

    span {
        font-size: 1.7em;
        font-weight: 600;
        padding-left: 15px;
    }
}

.cart_item {
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;

    .cart_item_section {
        display: flex;
        height: 180px;
        gap: 2vw;

        .cart_image_section {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;

            .cart_item_image {
                width: 180px;
                height: auto;
                border-radius: 7px;
            }
        }

        .product_description_section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px 0;
            height: 165px;
            width: 100%;

            .items_name {
                font-size: 1.2em;
                font-weight: 600;
            }

            .items_description {
                width: 85%;
                height: auto;
                background: rgb(226, 221, 215);
                border-radius: 5px;
                padding: 10px 15px;
                margin: 5px 0;
                text-align: left;
            }
        }
    }

    .discount_price_section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        height: 20px;
        gap: 5px;
        margin-top: 1vh;

        .discount_name {
            font-size: 1.1rem;
            color: rgb(0, 102, 255);
            font-weight: 600;
        }

        .original_price {
            font-weight: 600;
        }

        .price {
            font-size: 1.1rem;
            font-weight: 600;
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .quantity_container {
        width: 95%;
        margin: 2vh 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5vw;
    }

    .counter {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin-left: 3px;
    }

    .quantity_button_1 {
        width: 35px;
        height: 36px;
        border: 1px solid black;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        &:hover {
            background: rgb(233, 226, 219);
        }

        img {
            width: 20px;
            height: auto;
        }
    }

    .quantity_button_2 {
        width: 35px;
        height: 36px;
        border: 1px solid black;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: rgb(233, 226, 219);
        }

        img {
            width: 20px;
            height: auto;
        }
    }

    .quantity {
        width: 50px;
        height: 33px;
        border: 1px solid black;
        text-align: center;
        font-size: 1.3em;
        font-weight: 500;
        background: transparent;
    }

    .button_remove {
        width: 110px;
        height: 33px;
        border: 1px solid rgba(234, 39, 39, 0.85);
        border-radius: 7px;
        background: rgba(234, 39, 39, 0.85);
        color: white;
        font-size: 1em;
        font-weight: 600;

        &:hover {
            background: rgb(219, 0, 0);
        }
    }
}

.middle_section {
    width: 100%;
    height: 30%;
    // padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    
    .cart_total {
        font-weight: bold;
        font-size: 1.2em;
        padding-top: 2vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        border-top: 2px solid rgb(66, 66, 66);
        

        .section{
            width: 90%;
            font-size: 1.1em;
            display: flex;
            justify-content: space-between;
        }

        .saved_price {
            color: rgb(0, 102, 255);
        }

        .total_price{
            padding: 0 0;
        }
    }

    .checkout_section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;

        .checkout_button {
            padding: 1vh 12vw;
            font-size: 1.2em;
            font-weight: 600;
            color: white;
            border: 2px solid rgb(0, 102, 255);
            border-radius: 7px;
            background: rgb(0, 102, 255);
            transition: .5s ease;

            &:hover{
                color: rgb(0, 102, 255);
                background: transparent;
            }
        }
    }
}
