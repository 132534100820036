@media (max-width: 480px) {
    .wrapper_questions {
        padding: 3vh 0;

        .questions {
            width: 90%;
            gap: 2vh;

            .main_item {
                width: 100%;

                span {
                    font-size: 2.5rem;
                    font-family: "Barlow-Bold";
                }
            }
            .item {
                width: 100%;
                font-size: 1.2rem;
                font-weight: 600;

                .itm {
                    gap: 1vw;
                }
                .iconArrow {
                    width: 25px;
                }
                .iconDone {
                    width: 20px;
                }
                .answer {
                    margin-left: 5vw;

                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_questions {
        padding: 3vh 0;

        .questions {
            width: 75%;
            gap: 2vh;

            .main_item {
                width: 100%;

                span {
                    font-size: 2.7rem;
                    font-family: "Barlow-Bold";
                }
            }
            .item {
                width: 100%;
                font-size: 1.2rem;
                font-weight: 600;

                .itm {
                    gap: 1vw;
                }
                .iconArrow {
                    width: 25px;
                }
                .iconDone {
                    width: 20px;
                }
                .answer {
                    margin-left: 5vw;

                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_questions {
        padding: 3vh 0;

        .questions {
            width: 75%;
            gap: 2vh;

            .main_item {
                width: 100%;

                span {
                    font-size: 2.7rem;
                    font-family: "Barlow-Bold";
                }
            }
            .item {
                width: 100%;
                font-size: 1.2rem;
                font-weight: 600;

                .itm {
                    gap: 1vw;
                }
                .iconArrow {
                    width: 25px;
                }
                .iconDone {
                    width: 20px;
                }
                .answer {
                    margin-left: 5vw;

                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}


