body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: normal;  
}

    /* fonts for max-width: 450px*/
@font-face { 
  font-family: "Barlow-Regular";
  src: url(./fonts//Barlow-Regular.ttf);
}

@font-face {
  font-family: "Barlow-Bold";
  src: url(./fonts//Barlow-Bold.ttf);
}

@font-face {
  font-family: "Barlow-Medium";
  src: url(./fonts//Barlow-Medium.ttf);
}

