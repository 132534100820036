.wrapper_result {
    width: 100%;
    padding: 7vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.guaranteed_result {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    font-weight: 800;
}

.result_pic_section {
    width: 90%;
}

.arrows{
    width: 100%;
    text-align: center;
    padding: 2vh 0;
}

.slider-wrapper {
    width: 100%;
}

.pic {
   margin: 0 5%;

    img {
        // max-width: 250px;
        width: 90%;
        height: auto;
        border-radius: 15px;
    }
}

.arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    // position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    img {
        width: 90px;
    }
}

.arrow:hover {
    transform: scale(1.2);
}
