.wrapper_footer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 245, 232);
    padding-top: 7vh;
  
    .footer{
        width: 85%;
        height: 30vh;
        display: flex;
        justify-content: space-between;
        gap: 5vw;

        .first_section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 2;
            height: 30vh;
            width: calc(100/3);         

            .logo_name{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                position: relative;
                top: -45px;

                .item1{
                    width: 140px;
                    height: auto;
                    display: block;
                    
                }
                // .item2{
                //     text-align: center;
                //     font-size: 1.5em;
                //     font-weight: 600;
                //     // color: rgb(211, 26, 59); 
                //     padding: 1vh 0;
                // }

            }
            .button_section{
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;

                .button_follow{
                    width: auto;
                    padding: 1vh 1vw;
                    height: 40px;
                    border: 2px solid rgb(255, 79, 0);
                    border-radius: 20px;
                    font-size: 1em;
                    background-color: rgb(255, 79, 0);
                    color: white;
                }
                    .button_follow:hover{
                        background-color: rgb(235, 75, 0);
                        border-color: rgb(235, 75, 0);
                        cursor: pointer;
                    }
            }
        }

        .second_section{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: left;
            width: calc(100/3);
            height: 30vh;
            gap: 2vh;
            flex-grow: 1;

            .item_gen{
                font-size: 1.5em;
                font-weight: 600;
                padding-bottom: 1vh;
            }
            .item{
                text-decoration: none;
                color: rgb(83, 83, 83);
            }
            .item:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .third_section{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 30vh;
            width: calc(100/3);
            gap: 2vh;
            
            .input_email_section{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: left;
                height: auto;
                gap: 2vh;
                margin-bottom: 1vh;

                .item_gen{
                    font-size: 1.5em;
                    font-weight: 600;
                    padding-bottom: 1vh;
                }
    
                .item{
                    width: 80%;
                    color: rgb(83, 83, 83);
                }
    
                .input_email{
                    width: 60%;
                    padding: 1vh 0;
                    border: 1px solid rgb(132, 132, 132);
                    border-radius: 7px;
                    font-size: 1.1em;
                    font-weight: 500;
                    padding-left: 1vw;
                    margin-bottom: 1vh;
                }
    
                .input_email:hover{
                    cursor: pointer;
                    border: 1px solid black;
                    
                }
                .submit_email{
                    max-width: 150px;
                    padding: .7vh 0;
                    border: 1px solid rgb(235, 75, 0);
                    border-radius: 7px;
                    background: rgb(235, 75, 0);
                    color: white;
                    font-size: 1.1em;  

                    &:hover{
                        background: rgb(255, 81, 0);
                    }
                }
            }
        }
   
        .social_section{
            padding: 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2vw;
            width: 100%;

            .item{
                width: 40px;
                height: auto;
            }
            .item:hover{
                cursor: pointer;
                transform: scale(1.1);
            }

        }
    }
    
    .privacy_policy_section{
        border-top: 1px solid rgb(169, 169, 169);
        display: flex;
        justify-content: center;
        align-items:flex-end;
        width: 100%;
        margin-top: 10vh;

            li{
                display: inline;
            }
            li::before{
                content: "\2022";
                padding: 0 .8rem;
                color: rgb(83, 83, 83);
            }
            li:first-child::before{
                content: none;
            }
            .link{
                text-decoration: none;
                font-size: 0.8em;
                color: rgb(83, 83, 83);
            }
            .link:hover{
                text-decoration: underline;
                cursor: pointer;
            }
    }
}

