.App {
  width: 100%;
  /* max-width: 1920px; */
  min-height: 100vh;
  margin: 0 auto;
  background: linear-gradient(to left,  rgb(217, 153, 41), rgba(234, 39, 39, 0.95)); 
}

.Main{
  width: 100%;
  margin: 0 auto;
}


*{
  font-family: "Montserrat-Medium";
}

 ::-webkit-scrollbar{
  width: 0;
}
