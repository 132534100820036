@media (max-width: 480px) {

    .confirm_dialog{
        width: 75%;
        height: 20%;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        h2{
            margin: 0;
            margin-top: 15px;
        }

        p{
            margin: 5px;
        }
    }

    .confirm_dialog_buttons{
        margin: 0;

        button{
            padding: 8px 20px;
        }
    }
}