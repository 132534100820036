.wrapper_product_description{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.product_description{
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 5vh 0;
    padding: 5vh 0;
    gap: 3vh;
    border: 3px solid white;
    border-radius: 35px;

    .text_section1{
        text-align: center;
        width: 70%;
        font-size: 2.7em;
        font-weight: 900;
        color: white;
    }

    .text_section2{
        text-align: center;
        width: 70%;
        font-size: 1.5em;
        color: white;
    }

    .button_get{
        transition: 0.5s;
        height: auto;
        width: auto;
        padding: 1.4vh 4.5vw;
        background: white;
        border: 2px solid white;
        border-radius: 7px;
        font-size: 1.3em;
        font-weight: 600;
        color: rgb(255, 79, 0);
    }

    .button_get:hover{
        padding: 1.4vh 6vw;
        background: none;
        color: white;
        cursor: pointer;
    }

    .screenImg{
        width: 800px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1vw;

        img{
            margin: 0 5vw;
            max-width: 90%;
            height: auto;
            border-radius: 20px;
        }
    }
}



