.product_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.product_modal {
    min-width: 50%;
    min-height: 50%;
    width: 800px;
    height: auto;
    background: rgb(255, 245, 232);
    // padding: 0 45px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;

    .product_modal_image {
        height: auto;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        padding: 0 1vw;

        img {
            width: 350px;
            height: auto;
            border-radius: 10px;
        }

        .button_modal_close_2 {
            display: none;
        }
    }

    .product_modal_details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 340px;

        .left_side {
            height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 3vh;

            .product_name {
                font-size: 2em;
                font-weight: 600;
            }

            .product-description {
                font-size: clamp(1rem, 1.0727rem + 0.223vw, 1.3rem);
                padding: 0 1vw;
            }
            .button-section{
                text-align: center;
            }
        }

        .right_side {
            display: flex;
            justify-content: flex-start;
            position: relative;
            width: 100%;

            .button_modal_close {
                background: transparent;
                border: none;
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 1vw;
                top: -3vh;

                img {
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .price_section {
            display: flex;
            justify-content: flex-end;
            width: 90%;
            font-size: 1.5em;
            gap: 1vw;

            .discounted_price {
                color: rgb(0, 102, 255);
                font-weight: 600;
            }
        }

        .saving_price_section {
            display: flex;
            justify-content: space-between;
            width: 90%;
            font-size: 1.5em;
        }
    }
}
