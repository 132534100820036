@media (max-width: 480px) {
    .wrapper_top {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            visibility: hidden;
            transition:
            opacity 0.3s ease,
            visibility 0.3s ease;
            z-index: 1;
        }

        .overlay.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .header {
        width: 100%;

        .navigation_wrapper {
            height: 40px;
            padding: 30px 0;
            justify-content: center;

            .burger-menu {
                display: block;
                background: transparent;
                border: none;
                color: white;
                display: flex;
                width: 30vw;

                .iconBurger {
                    width: 30px;
                    height: 30px;
                    fill: #ffffff;
                }

                .iconBurger.shrink {
                    fill: #000000;
                }
            }

            .nav_bar {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                position: absolute;
                top: 0;
                left: -80%;
                width: 80%;
                height: 100vh;
                background: rgb(255, 245, 232);
                z-index: 2;
                transition: transform 0.2s linear;

                .wrapper_close_button {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-right-radius: 16px;
                    border-bottom: 1px solid rgb(163, 163, 163);

                    .menu {
                        position: relative;
                        top: 0;
                        left: 25px;
                        font-size: 1.5rem;
                        font-weight: bolder;
                    }

                    .close_button {
                        width: 50px;
                        height: auto;
                        background: transparent;
                        border-top-right-radius: 16px;
                        border: none;
                        font-size: 1.8rem;
                        align-items: center;
                        position: relative;
                        top: 0;
                        left: -5px;

                        &:hover {
                            text-decoration: underline;
                        }

                        .iconClose {
                            width: 20px;
                            height: auto;
                        }
                    }
                }

                .link {
                    width: 100%;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: black;
                    position: relative;
                    top: 0;
                    left: 0;
                    // margin-left: 25px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                .link:active,
                .link.selected{

                    &:hover{
                        background: none;
                    }

                }

                .burger_menu_social {
                    width: 100%;
                    height: 50px;
                    border-top: 1px solid rgb(163, 163, 163);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    padding-top: 10px;

                    .item {
                        width: 35px;
                        height: auto;
                    }
                }

                .privacy_policy_section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 70%;

                    .table_privacy {
                        border-top: 1px solid rgb(169, 169, 169);
                        text-align: center;
                        width: 100%;
                        height: 50px;
                        padding: 1vh 0;
                        margin: 0 0;

                        li {
                            display: inline;
                            font-size: 1rem;
                        }
                        li::before {
                            content: "\2022";
                            padding: 0 0.3rem 0 0.5rem;
                            color: rgb(83, 83, 83);
                        }
                        li:first-child::before {
                            content: none;
                        }
                        .links {
                            text-decoration: none;
                            font-size: 0.8rem;
                            color: rgb(83, 83, 83);
                        }
                        .links:hover {
                            text-decoration: none;
                            background: none;
                        }

                    }
                }
            }

            .nav_bar.shrink{

                .link:hover{
                    color: black;
                    background: none;
                }
            }

            .nav_bar .privacy_policy_section {
                align-items: flex-end;
            }

            .logo {
                display: flex;
                justify-content: center;
                align-items: center;

                .iconLogo {
                    width: 95px;
                    height: 95px;
                    position: relative;
                    top: 0;
                }
            }

            .shopping_basket {
                color: white;

                .iconBasket {
                    width: 35px;
                    height: 35px;
                }
            }

            .shopping_basket.shrink {
                color: black;
            }
        }
        .nav_bar.active {
            transform: translateX(100%);
        }
        .burger-menu.active {
            img {
                display: none;
            }
        }

        .logo.shrink {
            display: flex;
            flex-direction: row;

            // .iconLogo {

            // }

            .item {
                display: block;
                font-size: 2rem;
            }

            .item1 {
                animation-name: wiggle;
                animation-duration: 0.7s;
                animation-fill-mode: forwards;
                animation-iteration-count: 0.7s;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }

            .item2 {
                color: red;
                animation-name: wiggle2;
                animation-duration: 0.9s;
                animation-fill-mode: forwards;
                animation-iteration-count: 0.7s;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }
        }
        .navigation_wrapper.shrink {
            padding: 25px 0;
            height: 30px;
            width: 100%;
        }
    }
    .wrapper_middle {
        height: 95vh;

        position: relative;
        .information_about_company {
            width: 90%;

            .company_name {
                width: 100%;
                font-size: 3.2rem;
                // font-weight: 900;
                font-family: "Barlow-Bold";
            }

            .description {
                width: 100%;
                font-size: 1.4rem;

                span{
                    line-height: normal;
                }
            }

            .buttons {
                width: 90%;
                gap: 10px;

                .button {
                    width: 100%;
                    height: 50px;
                    font-size: 1.2rem;
                    font-family: "Barlow-Bold";
                }
            }
        }
    }

    .cart-icon-badge {
        top: 2.8vh;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_top {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            visibility: hidden;
            transition:
                opacity 0.3s ease,
                visibility 0.3s ease;
            z-index: 1;
        }

        .overlay.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .header {
        width: 100%;

        .navigation_wrapper {
            height: 40px;
            padding: 35px 0;
            justify-content: center;

            .burger-menu {
                display: block;
                background: transparent;
                border: none;
                color: white;
                display: flex;
                width: 30vw;

                .iconBurger {
                    width: 30px;
                    height: auto;
                    fill: rgb(255, 255, 255);
                }

                .iconBurger.shrink {
                    fill: rgb(0, 0, 0);
                }
            }

            .nav_bar {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                position: absolute;
                top: 0;
                left: -55%;
                width: 55%;
                height: 100vh;
                background: rgb(255, 245, 232);
                z-index: 1;
                transition: transform 0.2s linear;

                .wrapper_close_button {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-right-radius: 16px;
                    border-bottom: 1px solid rgb(163, 163, 163);

                    .menu {
                        position: relative;
                        top: 0;
                        left: 25px;
                        font-size: 1.5rem;
                        font-weight: bolder;
                    }

                    .close_button {
                        width: 50px;
                        height: auto;
                        background: transparent;
                        border-top-right-radius: 16px;
                        border: none;
                        font-size: 1.8rem;
                        align-items: center;
                        position: relative;
                        top: 5px;
                        left: -10px;

                        &:hover {
                            text-decoration: underline;
                        }

                        .iconClose {
                            width: 30px;
                            height: auto;
                        }
                    }
                }

                .link {
                    width: 100%;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: black;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin-left: 25px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .burger_menu_social {
                    width: 100%;
                    height: 50px;
                    border-top: 1px solid rgb(163, 163, 163);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    padding-top: 10px;

                    .item {
                        width: 30px;
                        height: auto;
                    }
                }

                .privacy_policy_section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 80%;

                    .table_privacy {
                        border-top: 1px solid rgb(169, 169, 169);
                        text-align: center;
                        width: 100%;
                        height: 50px;
                        padding: 1vh 1%;
                        margin: 0 0;

                        li {
                            display: inline;
                        }
                        li::before {
                            content: "\2022";
                            padding: 0 0.3rem 0 0.5rem;
                            color: rgb(83, 83, 83);
                        }
                        li:first-child::before {
                            content: none;
                        }
                        .links {
                            text-decoration: none;
                            font-size: .9rem;
                            color: rgb(83, 83, 83);
                        }
                        .links:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .nav_bar.shrink{

                .link:hover{
                    color: black;
                    background: none;
                }
            }

            .nav_bar .privacy_policy_section {
                align-items: flex-end;
            }

            .logo {
                .iconLogo {
                    width: 100px;
                    height: 100px;
                    position: relative;
                    top: 0;
                }
            }

            .shopping_basket {
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .nav_bar.active {
            transform: translateX(100%);
        }
        .burger-menu.active {
            img {
                display: none;
            }
        }

        .logo.shrink {
            display: flex;
            flex-direction: row;

            .iconLogo {
                display: none;
            }

            .item {
                display: block;
                font-size: 2rem;
            }

            .item1 {
                animation-name: wiggle;
                animation-duration: 1s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }

            .item2 {
                color: red;
                animation-name: wiggle2;
                animation-duration: 1.2s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }
        }
        .navigation_wrapper.shrink {
            padding: 30px 0;
            height: 30px;
            width: 100%;
        }
    }

    .wrapper_middle {
        height: 95vh;
        position: relative;
        top: -30px;

        position: relative;
        .information_about_company {
            width: 100%;

            .company_name {
                width: 50%;
                font-size: 3.5rem;
                font-family: "Barlow-Bold";
            }

            .description {
                width: 70%;
                font-size: 1.6rem;
            }

            .buttons {
                width: 60%;
                gap: 20px;

                .button {
                    width: 100%;
                    height: 50px;
                    font-size: 1.5rem;
                    font-family: "Barlow-Bold";
                }
            }
        }
    }
    .cart-icon-badge {
        top: 2.8vh;
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .wrapper_top {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            visibility: hidden;
            transition:
                opacity 0.3s ease,
                visibility 0.3s ease;
            z-index: 1;
        }

        .overlay.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .header {
        width: 100%;

        .navigation_wrapper {
            height: 45px;
            padding: 35px 0;
            justify-content: center;

            .burger-menu {
                display: block;
                background: transparent;
                border: none;
                color: white;
                display: flex;
                width: 30vw;

                .iconBurger {
                    width: 40px;
                    height: auto;
                    fill: rgb(255, 255, 255);
                }

                .iconBurger.shrink {
                    fill: rgb(0, 0, 0);
                }
            }

            .nav_bar {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                position: absolute;
                top: 0;
                left: -60%;
                width: 60%;
                height: 100vh;
                background: rgb(255, 245, 232);
                z-index: 1;
                transition: transform 0.2s linear;

                .wrapper_close_button {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-right-radius: 16px;
                    border-bottom: 1px solid rgb(163, 163, 163);

                    .menu {
                        position: relative;
                        top: 0;
                        left: 25px;
                        font-size: 1.5rem;
                        font-weight: bolder;
                    }

                    .close_button {
                        width: 50px;
                        height: auto;
                        background: transparent;
                        border-top-right-radius: 16px;
                        border: none;
                        font-size: 1.8rem;
                        align-items: center;
                        position: relative;
                        top: 5px;
                        left: -10px;

                        &:hover {
                            text-decoration: underline;
                        }

                        .iconClose {
                            width: 30px;
                            height: auto;
                        }
                    }
                }

                .link {
                    width: 100%;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: black;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin-left: 25px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .burger_menu_social {
                    width: 100%;
                    height: 50px;
                    border-top: 1px solid rgb(163, 163, 163);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    padding-top: 10px;

                    .item {
                        width: 30px;
                        height: auto;
                    }
                }

                .privacy_policy_section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 80%;

                    .table_privacy {
                        border-top: 1px solid rgb(169, 169, 169);
                        text-align: center;
                        width: 100%;
                        height: 50px;
                        padding: 1vh 1vw;
                        margin: 0 0;

                        li {
                            display: inline;
                        }
                        li::before {
                            content: "\2022";
                            padding: 0 0.3rem 0 0.5rem;
                            color: rgb(83, 83, 83);
                        }
                        li:first-child::before {
                            content: none;
                        }
                        .links {
                            text-decoration: none;
                            font-size: 1rem;
                            color: rgb(83, 83, 83);
                        }
                        .links:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .nav_bar.shrink{

                .link:hover{
                    color: black;
                    background: none;
                }
            }

            .nav_bar .privacy_policy_section {
                align-items: flex-end;
            }

            .logo {
                img {
                    width: 110px;
                    height: 110px;
                    position: relative;
                    top: 1vw;
                }
            }

            .shopping_basket {
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .nav_bar.active {
            transform: translateX(100%);
        }
        .burger-menu.active {
            img {
                display: none;
            }
        }

        .logo.shrink {
            img {
                width: 110px;
                height: 110px;
                top: 0;
            }
        }
        .navigation_wrapper.shrink {
            height: 45px;
            padding: 35px 0;
            width: 100%;
        }
    }

    .wrapper_middle {
        height: 95vh;
        position: relative;
        top: -50px;

        .information_about_company {
            width: 100%;

            .company_name {
                width: 50%;
                font-size: 3.5rem;
                font-family: "Barlow-Bold";
            }

            .description {
                width: 70%;
                font-size: 1.6rem;
            }

            .buttons {
                width: 50%;
                gap: 20px;

                .button {
                    width: 100%;
                    height: 50px;
                    font-size: 1.6rem;
                    font-family: "Barlow-Bold";
                }
            }
        }
    }
    .cart-icon-badge {
        top: 2.5vh;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1124px) {
    .wrapper_top {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            visibility: hidden;
            transition:
                opacity 0.3s ease,
                visibility 0.3s ease;
            z-index: 1;
        }

        .overlay.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .header {
        width: 100%;

        .navigation_wrapper {
            height: 40px;
            padding: 40px 0;
            justify-content: center;

            .burger-menu {
                display: block;
                background: transparent;
                border: none;
                color: white;
                display: flex;
                width: 30vw;

                .iconBurger {
                    width: 35px;
                    height: auto;
                    fill: rgb(255, 255, 255);
                }

                .iconBurger.shrink {
                    fill: rgb(0, 0, 0);
                }
            }

            .nav_bar {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                position: absolute;
                top: 0;
                left: -60%;
                width: 60%;
                height: 100vh;
                background: rgb(255, 245, 232);
                z-index: 1;
                transition: transform 0.2s linear;

                .wrapper_close_button {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-right-radius: 16px;
                    border-bottom: 1px solid rgb(163, 163, 163);

                    .menu {
                        position: relative;
                        top: 0;
                        left: 25px;
                        font-size: 1.5rem;
                        font-weight: bolder;
                    }

                    .close_button {
                        width: 50px;
                        height: auto;
                        background: transparent;
                        border-top-right-radius: 16px;
                        border: none;
                        font-size: 1.8rem;
                        align-items: center;
                        position: relative;
                        top: 5px;
                        left: -10px;

                        &:hover {
                            text-decoration: underline;
                        }

                        .iconClose {
                            width: 30px;
                            height: auto;
                        }
                    }
                }

                .link {
                    width: 100%;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: black;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin-left: 25px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .burger_menu_social {
                    width: 100%;
                    height: 50px;
                    border-top: 1px solid rgb(163, 163, 163);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    padding-top: 10px;

                    .item {
                        width: 30px;
                        height: auto;
                    }
                }

                .privacy_policy_section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 80%;

                    .table_privacy {
                        border-top: 1px solid rgb(169, 169, 169);
                        text-align: center;
                        width: 100%;
                        height: 50px;
                        padding: 1vh 1vw;
                        margin: 0 0;

                        li {
                            display: inline;
                        }
                        li::before {
                            content: "\2022";
                            padding: 0 0.3rem 0 0.5rem;
                            color: rgb(83, 83, 83);
                        }
                        li:first-child::before {
                            content: none;
                        }
                        .links {
                            text-decoration: none;
                            font-size: 1rem;
                            color: rgb(83, 83, 83);
                        }
                        .links:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .nav_bar.shrink{

                .link:hover{
                    color: black;
                    background: none;
                }
            }

            .nav_bar .privacy_policy_section {
                align-items: flex-end;
            }

            .logo {
                img {
                    width: 110px;
                    height: 110px;
                    position: relative;
                    top: 1vw;
                }
            }

            .shopping_basket {
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .nav_bar.active {
            transform: translateX(100%);
        }
        .burger-menu.active {
            img {
                display: none;
            }
        }

        .logo.shrink {
            img {
                width: 110px;
                height: 110px;
                top: 0;
            }
        }
        .navigation_wrapper.shrink {
            height: 40px;
            padding: 40px 0;
            width: 100%;
        }
    }

    .wrapper_middle {
        height: 95vh;
        position: relative;
        top: -50px;

        .information_about_company {
            width: 100%;

            .company_name {
                width: 50%;
                font-size: 3.7rem;
                font-family: "Barlow-Bold";
            }

            .description {
                width: 50%;
                font-size: 1.4rem;
            }

            .buttons {
                width: 40%;
                gap: 20px;

                .button {
                    width: 100%;
                    height: 55px;
                    font-size: 1.4rem;
                    font-family: "Barlow-Bold";
                }
            }
        }
    }
    .cart-icon-badge {
        top: 2.1vh;
    }
}

@media screen and (min-width: 1125px) and (max-width: 1301px) {
    .header {
        .navigation_wrapper {
            height: 45px;
            .nav_bar {
                .link {
                    font-size: 1.2em;
                }
            }
        }
    }
    .wrapper_middle {
        .information_about_company {
            width: 50%;
            .company_name {
                font-size: 3rem;
            }
            .description {
                width: 100%;
                font-size: 1.5rem;
            }

            .buttons {
                width: 100%;
            }
            .button {
                font-size: 1.2rem;
                width: 18vw;
            }
        }
    }
}

@keyframes wiggle {
    0% {
        font-weight: 500;
        transform: translateX(0);
        opacity: 0.2;
    }

    30% {
        font-weight: 800;
        transform: translateX(-13px);
        opacity: 0.4;
    }
    60% {
        font-weight: 800;
        transform: translateX(10px);
        opacity: 0.7;
    }
    100% {
        font-weight: 500;
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes wiggle2 {
    0% {
        font-weight: 800;
        transform: translateX(0);
        opacity: 0.2;
    }

    30% {
        font-weight: 400;
        transform: translateX(10px);
        opacity: 0.4;
    }
    60% {
        font-weight: 400;
        transform: translateX(-13px);
        opacity: 0.7;
    }
    100% {
        font-weight: 800;
        transform: translateX(0);
        opacity: 1;
    }
}
