.home_page{
    width: 100%;
}
.fade-in-section {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s ease-out, transform 0.6s ease-out;
}

.fade-in-section.visible {
    opacity: 1;
    transform: translateY(0);
}
