@media (max-width: 450px) {
    .product_modal{
        width: 80%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 5px;
        gap: 1vh;

        .product_modal_image{
            width: 100%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 1vh;

            img{
                width: 250px;
                height: auto;
            }

            .button_modal_close_2{
                background: transparent;
                border: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .product_modal_details{
            width: 100%;
            height: auto;

            .left_side{
                width: 90%;
                align-items: center;
                text-align: center;
                gap: 1vh;
                padding-bottom: 10px;
            }

            .right_side{

                .button_modal_close{
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px){
    .product_modal{
        width: 55%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 1vh;

        .product_modal_image{
            width: 100%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 0;

            img{
                width: 250px;
                height: auto;
            }

            .button_modal_close_2{
                background: transparent;
                border: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .product_modal_details{
            width: 90%;
            height: auto;

            .left_side{
                width: 100%;
                align-items: center;
                text-align: center;
                gap: 1vh;
                padding-bottom: 10px;
            }

            .right_side{

                .button_modal_close{
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .product_modal{
        width: 55%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 1vh;

        .product_modal_image{
            width: 100%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 0;

            img{
                width: 250px;
                height: auto;
            }

            .button_modal_close_2{
                background: transparent;
                border: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .product_modal_details{
            width: 90%;
            height: auto;

            .left_side{
                width: 100%;
                align-items: center;
                text-align: center;
                gap: 1vh;
                padding-bottom: 10px;
            }

            .right_side{

                .button_modal_close{
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1124px) {
    .product_modal{
        width: 50%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 1vh;

        .product_modal_image{
            width: 100%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 0;

            img{
                width: 300px;
                height: auto;
            }

            .button_modal_close_2{
                background: transparent;
                border: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .product_modal_details{
            width: 80%;
            height: auto;

            .left_side{
                width: 100%;
                align-items: center;
                text-align: center;
                gap: 1vh;
                padding-bottom: 10px;
            }

            .right_side{

                .button_modal_close{
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 1125px) and (max-width: 1366px) {
    .product_modal{
        width: 50%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 1vh;

        .product_modal_image{
            width: 100%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 0;

            img{
                width: 300px;
                height: auto;
            }

            .button_modal_close_2{
                background: transparent;
                border: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .product_modal_details{
            width: 80%;
            height: auto;

            .left_side{
                width: 100%;
                align-items: center;
                text-align: center;
                gap: 1vh;
                padding-bottom: 10px;
            }

            .right_side{

                .button_modal_close{
                    display: none;
                }
            }
        }
    }
}