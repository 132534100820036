@media (max-width: 480px) {
    .cart_modal {
        width: 75%;

        .cart_item {
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;

            .cart_item_section {
                flex-direction: row;
                height: auto;

                .cart_image_section {
                    height: auto;
                    justify-content: flex-start;

                    img {
                        width: 120px;
                        height: auto;
                    }
                }

                .product_description_section {
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    gap: 1vh;
                    padding: 0;
                    padding-left: 10px;

                    .items_name {
                        width: 100%;
                        text-align: start;
                        font-size: 1.5rem;
                    }

                    .items_description {
                        display: none;
                    }

                    .discount_price_section {
                        height: auto;
                        width: 95%;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount_name {
                            font-size: 1rem;
                        }
                    }

                    .prices {
                        .original_price {
                            font-size: 1rem;
                        }

                        .price {
                            font-size: 1rem;
                        }
                    }
                }
            }
            .quantity_container {
                width: 100%;
                gap: 0;

                .counter {
                    height: 30px;
                    position: relative;

                    .quantity {
                        padding: 0;
                        border-radius: unset;
                        font-size: 1.3rem;
                        font-weight: 600;
                        width: 50px;
                        height: 28px;
                    }

                    .quantity_button_1 {
                        height: 30px;
                        width: 30px;
                        font-size: 1rem;

                        &:hover {
                            background: rgb(233, 226, 219);
                            color: black;
                            border-color: black;
                        }

                        img {
                            width: 15px;
                            height: auto;
                        }
                    }
                    .quantity_button_2 {
                        height: 30px;
                        width: 30px;
                        font-size: 1rem;

                        &:hover {
                            background: rgb(233, 226, 219);
                            color: black;
                            border-color: black;
                        }

                        img {
                            width: 15px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    .middle_section {
        height: auto;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .cart_modal {
        width: 60%;

        .cart_item {
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;

            .cart_item_section {
                flex-direction: row;
                height: auto;

                .cart_image_section {
                    height: auto;
                    justify-content: flex-start;

                    img {
                        width: 130px;
                        height: auto;
                    }
                }

                .product_description_section {
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    gap: 1vh;
                    padding-left: 10px;

                    .items_name {
                        width: 100%;
                        text-align: start;
                        font-size: 1.5rem;
                    }

                    .items_description {
                        display: none;
                    }

                    .discount_price_section {
                        height: auto;
                        width: 95%;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount_name {
                            font-size: 1rem;
                        }
                    }

                    .prices {
                        .original_price {
                            font-size: 1rem;
                        }

                        .price {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .quantity_container {
                width: 99%;
                gap: 0;
                margin: 10px auto;

                .counter {
                    height: 30px;
                    position: relative;

                    .quantity {
                        padding: 0;
                        border-radius: unset;
                        font-size: 1.3rem;
                        font-weight: 600;
                        width: 50px;
                        height: 29px;
                    }

                    .quantity_button_1 {
                        height: 30px;
                        width: 30px;
                        font-size: 1rem;

                        &:hover {
                            background: rgb(233, 226, 219);
                            color: black;
                            border-color: black;
                        }

                        img {
                            width: 15px;
                            height: auto;
                        }
                    }
                    .quantity_button_2 {
                        height: 30px;
                        width: 30px;
                        font-size: 1rem;

                        &:hover {
                            background: rgb(233, 226, 219);
                            color: black;
                            border-color: black;
                        }

                        img {
                            width: 15px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .cart_modal {
        width: 60%;

        .cart_item {
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;

            .cart_item_section {
                flex-direction: row;
                height: auto;

                .cart_image_section {
                    height: auto;
                    justify-content: flex-start;

                    img {
                        width: 135px;
                        height: auto;
                    }
                }

                .product_description_section {
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    gap: 1vh;
                    padding-left: 10px;

                    .items_name {
                        width: 100%;
                        text-align: start;
                        font-size: 1.5rem;
                    }

                    .items_description {
                        display: none;
                    }

                    .discount_price_section {
                        height: auto;
                        width: 95%;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount_name {
                            font-size: 1rem;
                        }
                    }

                    .prices {
                        .original_price {
                            font-size: 1rem;
                        }

                        .price {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .quantity {
            padding: 0;
            border-radius: unset;
            font-size: 1.3rem;
            font-weight: 600;
            width: 50px;
            height: 29px;
        }

        .quantity_button_1 {
            width: 30px;
            height: 30px;
            font-size: 1rem;

            &:hover {
                background: transparent;
                color: black;
                border-color: black;
            }
        }
        .quantity_button_2 {
            width: 30px;
            height: 30px;
            font-size: 1rem;

            &:hover {
                background: transparent;
                color: black;
                border-color: black;
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1124px) {
    .cart_modal {
        width: 50%;

        .cart_item {
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;

            .cart_item_section {
                flex-direction: row;
                height: auto;

                .cart_image_section {
                    height: auto;
                    justify-content: flex-start;

                    img {
                        width: 135px;
                        height: auto;
                    }
                }

                .product_description_section {
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    gap: 1vh;
                    padding-left: 10px;

                    .items_name {
                        width: 100%;
                        text-align: start;
                        font-size: 1.5rem;
                    }

                    .items_description {
                        display: none;
                    }

                    .discount_price_section {
                        height: auto;
                        width: 95%;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount_name {
                            font-size: 1rem;
                        }
                    }

                    .prices {
                        .original_price {
                            font-size: 1rem;
                        }

                        .price {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .quantity_container {
                width: 90%;
                margin: 20px auto;
            }

            .quantity {
                padding: 0;
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (min-width: 1125px) and (max-width: 1200px) {
    .cart_modal {
        width: 50%;

        .cart_item {
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;

            .cart_item_section {
                flex-direction: row;
                height: auto;

                .cart_image_section {
                    height: auto;
                    justify-content: flex-start;

                    .cart_item_image {
                        width: 200px;
                        height: auto;
                    }
                }

                .product_description_section {
                    height: auto;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1vh;
                    padding: 0;

                    .items_name {
                        width: 100%;
                        text-align: start;
                        font-size: 1.5rem;
                    }

                    .items_description {
                    }

                    .discount_price_section {
                        height: auto;
                        width: 95%;
                        flex-direction: row;
                        gap: 5vw;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount_name {
                            font-size: 1rem;
                        }

                        .prices {
                            .original_price {
                                font-size: 1rem;
                            }

                            .price {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
            .quantity_container {
                width: 90%;
                margin: 20px auto;

                .quantity {
                    padding: 0;
                    border-radius: unset;
                    font-size: 1.3rem;
                    font-weight: 600;
                    width: 60px;
                    height: 35px;
                }
            }
        }
    }
}
