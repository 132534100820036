.wrapper_all_products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 7vh 0;
    // background: rgb(255, 245, 232);

    .qwe {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        padding-bottom: 2vh;

        span {
            font-size: 2.3em;
            font-weight: 900;
        }
    }
}

.all_products {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // gap: 3vw;
    padding: 4vh 0;
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: calc(30%);
    box-shadow: 0 0 20px 2px rgb(26, 26, 26, 0.5);
    background: white;
    max-width: 380px;

    .image_section {
        width: 100%;
        height: auto;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        img {
            max-width: 100%;
            height: auto;
            transition: 0.2s ease-in-out;
            display: block;

            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
        }
    }

    .card_footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5vh;
        width: 100%;
        height: auto;
        padding: 2vh 0;
        background: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .item1 {
            font-size: 1.3em;
            font-weight: 700;
            color: rgb(0, 0, 0);
        }

        .item2 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 1.2em;
            color: rgb(0, 102, 255);
            font-weight: 600;

            s {
                color: black;
                font-weight: 500;
                font-size: 0.9em;
            }
        }

        //     .item3{
        //         width: 70%;
        //         padding: 1.2vh 0;
        //         font-size: 1.1em;
        //         font-weight: 600;
        //         transition: 0.4s ease-in-out;
        //         border: 2px solid rgb(0, 102, 255);
        //         background: rgb(0, 102, 255);
        //         border-radius: 99rem;
        //         color: rgb(255, 255, 255);
        //         position: relative;
        //         overflow: hidden;
        //         z-index: 1;
        //         text-transform: uppercase;

        //         &:after {
        //             content: '';
        //             position: absolute;
        //             display: block;
        //             top: 0;
        //             left: 50%;
        //             transform: translateX(-50%);
        //             width: 0;
        //             height: 100%;
        //             background: white;
        //             z-index: -1;
        //             transition: width 300ms ease-in-out;
        //           }

        //           &:hover {
        //             color: rgb(0, 102, 255);
        //             background: white;
        //             &:after {
        //               width: 100%;
        //             }
        //           }
        //         }
    }

    // .btn-18,
    // .btn-18 *,
    // .btn-18 :after,
    // .btn-18 :before,
    // .btn-18:after,
    // .btn-18:before {
    //     border: 0 solid;
    //     box-sizing: border-box;
    // }
    // .btn-18 {
    //     -webkit-tap-highlight-color: transparent;
    //     background-color: rgb(0, 102, 255);
    //     color: rgb(255, 255, 255);
    //     cursor: pointer;
    //     font-size: 1.1em;
    //     font-weight: 900;
    //     line-height: 1.3;
    //     margin: 0;
    //     padding: 0;
    //     text-transform: uppercase;
    //     width: 80%;
    // }
    // .btn-18:disabled {
    //     cursor: default;
    // }
    // .btn-18:-moz-focusring {
    //     outline: auto;
    // }
    // .btn-18 svg {
    //     display: block;
    // }
    // .btn-18 [hidden] {
    //     display: none;
    // }
    // .btn-18 {
    //     border-radius: 99rem;
    //     border-width: 2px;
    //     padding: 0.8rem 3rem;
    //     z-index: 0;
    // }
    // .btn-18,
    // .btn-18 .text-container {
    //     overflow: hidden;
    //     position: relative;
    // }
    // .btn-18 .text-container {
    //     display: block;
    // }
    // .btn-18 .text {
    //     display: block;
    //     position: relative;
    // }
    // .btn-18:hover .text {
    //     -webkit-animation: move-up-alternate 0.3s forwards;
    //     animation: move-up-alternate 0.3s forwards;
    //     color: rgb(0, 102, 255);
    // }
    // @-webkit-keyframes move-up-alternate {
    //     0% {
    //         transform: translateY(0);
    //     }
    //     50% {
    //         transform: translateY(80%);
    //     }
    //     51% {
    //         transform: translateY(-80%);
    //     }
    //     to {
    //         transform: translateY(0);
    //     }
    // }
    // @keyframes move-up-alternate {
    //     0% {
    //         transform: translateY(0);
    //     }
    //     50% {
    //         transform: translateY(80%);
    //     }
    //     51% {
    //         transform: translateY(-80%);
    //     }
    //     to {
    //         transform: translateY(0);
    //     }
    // }
    // .btn-18:after,
    // .btn-18:before {
    //     --tilt: 20px;
    //     background: rgb(255, 255, 255);
    //     -webkit-clip-path: polygon(0 0, calc(100% - var(--tilt)) 0, 100% 50%, calc(100% - var(--tilt)) 100%, 0 100%);
    //     clip-path: polygon(0 0, calc(100% - var(--tilt)) 0, 100% 50%, calc(100% - var(--tilt)) 100%, 0 100%);
    //     content: "";
    //     height: 100%;
    //     left: -100%;
    //     position: absolute;
    //     top: 0;
    //     transition: transform 0.6s;
    //     width: 100%;
    // }
    // .btn-18:after {
    //     -webkit-clip-path: polygon(var(--tilt) 0, 0 50%, var(--tilt) 100%, 100% 100%, 100% 0);
    //     clip-path: polygon(var(--tilt) 0, 0 50%, var(--tilt) 100%, 100% 100%, 100% 0);
    //     left: 100%;
    //     z-index: -1;
    // }
    // .btn-18:hover:before {
    //     transform: translateX(100%);
    // }
    // .btn-18:hover:after {
    //     transform: translateX(-100%);
    // }

    // .card_footer:hover {
    //     .item1 {
    //         text-decoration: underline;
    //     }
    // }

    // .button_media{
    //     display: none;
    // }

    // .btn-new {
    //     border: none;
    //     border-radius: 10px;
    //     text-decoration: none;
    //     color: white;
    //     background: #0B63F6;
    //     box-shadow: 0 5px 0 #003CC5;
    //     padding: .4em 3.5rem;
    //     font-size: 1.4em;
    // }
    
    // .btn-new:hover {
    //     background: #003CC5;
    //     box-shadow: none;
    //     position: relative;
    //     top: 5px;
    // }

}
