@media (max-width: 480px) {
    .wrapper_result {
        padding: 4vh 0;

        .guaranteed_result {
            padding-bottom: 4vh;

            span {
                font-size: 2.5rem;
                font-family: "Barlow-Bold";
            }
        }
        .result_pic_section {
            // width: 98%;
        }
        .slider-wrapper {
            justify-content: center;
        }
        .slider {
        
        }

        .pic {
           

            img {
                
                border-radius: 10px;
            }
        }
        .arrow {
            img {
                width: 40px;
                height: 40px;
            }
        }
        .arrow:hover {
            transform: scale(1);
        }
        .arrows{
            display: none;
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_result {
        padding: 4vh 0;

        .guaranteed_result {
            padding-bottom: 4vh;

            span {
                font-size: 2.5rem;
                font-family: "Barlow-Bold";
            }
        }
     
        .pic {
        

            img {
                height: auto;
                border-radius: 10px;
            }
        }
        .arrow {
            img {
                width: 70px;
                height: 70px;
            }
        }
        .arrow:hover {
            transform: scale(1);
        }
        .arrows{
            display: none;
        }
    }
}

@media (min-width: 769px) and (max-width: 1023px) {
    .wrapper_result {
        padding: 4vh 0;

        .guaranteed_result {
            padding-bottom: 4vh;

            span {
                font-size: 2.5rem;
                font-family: "Barlow-Bold";
            }
        }

        .pic {
           

            img {
                height: auto;
                border-radius: 10px;
            }
        }
        .arrow {
            img {
                width: 70px;
                height: 70px;
            }
        }
        .arrow:hover {
            transform: scale(1);
        }
        .arrows{
            display: none;
        }

    }
}
