.modal_overlay_refund {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal_content_refund {
        background: white;
        padding: 20px;
        border-radius: 5px;
        position: relative;
        // max-width: 500px;
        width: 50%;
        height: 90%;

        .close_button_refund {
            position: absolute;
            left: 49vw;
            top: 2vh;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;

            img {
                width: 30px;
                height: auto;
            }
        }
    }
}

@media (max-width: 480px) {

    .modal_overlay_refund {

        .modal_content_refund {
            width: 70%;
            height: 80%;
            overflow-y: auto;

            .close_button_refund{
                left: 71vw;
                top: 1.5vh;

                img{
                    width: 25px;
                    height: auto;
                }
            }
        }
    }
}
