@media (max-width: 480px) {

    .product_description{
        padding: 4vh 0;
        margin: 0 0;
        width: 100%;
        border: none;
        gap: 2vh;

        .text_section1{
            width: 95%;
            font-size: 2.7rem;
            font-family: "Barlow-Bold";
            line-height: 40px;
        }

        .text_section2{
            width: 95%;
            font-size: 1.3rem;
            line-height: 25px;
        }

        // .button_get{
        //     width: 60%;
        //     height: 50px;
        //     font-size: 1.2rem;
        // }

        .button_get:hover{
            width: 60%;
        }
       

        .screenImg{
            width: 100vw;
            
            img{
                max-width: 95%;
                width: 400px;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {

    .product_description{
        padding: 4vh 0;
        margin: 0 0;
        width: 90%;
        border: none;
        gap: 2vh;

        .text_section1{
            width: 90%;
            font-size: 2.7rem;
            font-family: "Barlow-Bold";
            line-height: 40px;
        }

        .text_section2{
            width: 80%;
            font-size: 1.4rem;
            line-height: 25px;
        }

        .button_get{
            // width: 40%;
            // height: 50px;
            // font-size: 1.5rem;

            font-family: "Barlow-Bold";
        }

        .button_get:hover{
            padding: 1.4vh 4.5vw;
        }
       

        .screenImg{
            width: 100%;
            
            img{
                max-width: 95%;
                width: 700px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .product_description{
        padding: 4vh 0;
        margin: 0 0;
        width: 90%;
        border: none;
        gap: 2vh;

        .text_section1{
            width: 85%;
            font-size: 2.7rem;
            font-family: "Barlow-Bold";
            line-height: 40px;
        }

        .text_section2{
            width: 80%;
            font-size: 1.4rem;
            line-height: 25px;
        }

        .button_get{
            // width: 250px;
            // height: 55px;
            // font-size: 1.4rem;

            font-family: "Barlow-Bold";
        }

        .button_get:hover{
            padding: 1.4vh 4.5vw;
        }
       

        .screenImg{
            width: 100%;
            
            img{
                max-width: 95%;
                width: 700px;
            }
        }
    }
}


