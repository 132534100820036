.wrapper_questions {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8vh 0;
    
}

.questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 1.5vw;

    .main_item {
        font-size: 2.5em;
        text-align: center;
        font-weight: 800;
        color: black;
        padding-bottom: 2vh;
        width: 70%;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 45vw;
        border-bottom: 1px solid rgb(0, 0, 0);
        font-size: clamp(1rem, 0.8606rem + 0.5948vw, 1.4rem);
        font-weight: 600;
        transition: all 0.3s ease;

        & > .itm {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 2px;
        }

        span {
            width: 100%;
            order: 1;
        }

        .iconDone {
            width: 1.5vw;
            height: auto;
            order: 1;
        }

        .iconArrow {
            width: 1.8vw;
            height: auto;
            order: 2;
            cursor: pointer;
        }

        .answer {
            width: 90%;
            height: 100%;
            overflow: hidden;
            max-height: 100%;
            transition: max-height 0s ease, padding 0.4s ease;
            
            margin-left: 1.5vw;

            span{
                font-size: clamp(0.9375rem, 0.8155rem + 0.5204vw, 1.15rem);
                color: rgb(50, 50, 50);
                height: auto;
            }
        }

        &.expanded .answer {
            max-height: auto; 
            padding: 1vh 0;
        }
    }

}
