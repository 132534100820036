.wrapper_get{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 245, 232);
    padding: 7vh 0;

    .wrapper_name{
        margin-bottom: 4vh;
        font-size: 2.5em;
        font-weight: 800;
    }
    
    .get{
        width: 80%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .description_section{
            width: 50%;
            height: auto;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: left;
            padding: 1vw 0;
            margin-bottom: 2vh;
            // padding: 1vh 1vw;
            // border: 2px solid red;
            // border-radius: 20px;
        }
        .dis2{
            align-items: flex-end;
        }

        // .right_section{
        //     width: 45%;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: left;

        //     border: 2px solid red;
        // }

        .bonus{
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 20vh;
            gap: 1vh;

            .nameOfBonus{
                width: 100%;
                font-size: 1.5em;
                font-weight: 700; 
            }

            .nameOfBonus::before{
                content: "\2022";
                color: rgb(83, 83, 83);
                padding: 0 0.5vw;
            }
            .descriptionOfBonus{
                font-size: 1.2em;
                margin-left: 2vw;
            }
        }

        
    }
}