@media (max-width: 480px) {
    .wrapper_general_product {
        padding: 3vh 0;
        height: auto;

        .general_section {
            width: 90%;
            height: auto;

            .left_side {
                flex-direction: column-reverse;
                width: 100%;
                align-items: center;

                .left_small_section {
                    width: 90%;
                    gap: 0.9vh;

                    .product_name {
                        width: 100%;
                        font-size: 1.8rem;
                    }
                    .short_description {
                        font-size: 1.2rem;
                    }

                    .section_one {
                        font-size: 1.1rem;
                    }

                    .order_now {
                        font-size: 1rem;
                        margin-bottom: 1vh;
                    }

                    .cart_section {
                        justify-content: center;
                        align-items: center;
                    }
                }
                .right_img_side {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    // max-width: 90%;
                    top: -1.5vh;

                    .img {
                        position: static;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_general_product {
        padding: 3vh 0;
        height: auto;

        .general_section {
            width: 90%;
            height: auto;

            .left_side {
                flex-direction: column-reverse;
                width: 100%;
                align-items: center;

                .left_small_section {
                    width: 90%;
                    gap: 1vh;

                    .product_name {
                        width: 100%;
                        font-size: 2rem;
                    }
                    .short_description {
                        font-size: 1.4rem;
                    }

                    .section_one {
                        font-size: 1.2rem;
                    }

                    .order_now {
                        font-size: 1.2rem;
                        margin-bottom: 1vh;
                    }

                    .cart_section {
                        justify-content: center;
                        align-items: center;

                        .addToCart {
                            font-size: 1.6rem;
                            font-family: "Barlow-Bold";
                            font-weight: 600;
                            text-transform: uppercase;
                            width: 50vw;
                            color: aliceblue;
                            border-width: 2px;

                            &:hover {
                                background: transparent;
                                border-color: rgb(255, 2, 2);
                                border-width: 3px;
                            }
                        }
                    }
                }
                .right_img_side {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 500px;
                    // max-width: 90%;
                    top: -1.5vh;

                    .img {
                        position: static;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_general_product {
        padding: 3vh 0;
        height: auto;

        .general_section {
            width: 90%;
            height: auto;

            .left_side {
                flex-direction: column-reverse;
                width: 100%;
                align-items: center;

                .left_small_section {
                    width: 90%;
                    gap: 1vh;

                    .product_name {
                        width: 100%;
                        font-size: 2rem;
                    }
                    .short_description {
                        font-size: 1.4rem;
                    }

                    .section_one {
                        font-size: 1.2rem;
                    }

                    .order_now {
                        font-size: 1.2rem;
                        margin-bottom: 1vh;
                    }

                    .cart_section {
                        justify-content: center;
                        align-items: center;

                        .addToCart {
                            font-size: 1.6rem;
                            font-family: "Barlow-Bold";
                            font-weight: 600;
                            text-transform: uppercase;
                            width: 40vw;
                            height: 55px;
                            color: aliceblue;
                            border-width: 2px;

                            &:hover {
                                background: transparent;
                                border-color: rgb(255, 2, 2);
                                border-width: 3px;
                            }
                        }
                    }
                }
                .right_img_side {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 500px;
                    // max-width: 90%;
                    top: -1.5vh;

                    .img {
                        position: static;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
