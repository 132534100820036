@media (max-width: 480px) {
    .wrapper_get {
        padding: 5vh 0;
        

        .wrapper_name {
            font-size: 2.5rem;
            margin-bottom: 2vh;
            font-family: "Barlow-Bold";
        }
        .get {
            width: 80%;
            flex-direction: column;
 

            .description_section {
                width: 100%;
                height: auto;
                align-items: center;
                gap: 3vh;

                .bonus {
                    width: 100%;
                    height: auto;
                    .nameOfBonus {
                        width: 100%;
                        font-size: 1.5rem;
                    }

                    .descriptionOfBonus {
                        width: 100%;
                        font-size: 1.2rem;
                        margin-left: 4vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_get {
        padding: 5vh 0;

        .wrapper_name {
            font-size: 2.7rem;
            margin-bottom: 2vh;
            font-family: "Barlow-Bold";
        }
        .get {
            width: 80%;
            flex-direction: column;

            .description_section {
                width: 100%;
                height: auto;
                gap: 3vh;

                .bonus {
                    width: 100%;
                    height: auto;
                    .nameOfBonus {
                        width: 100%;
                        font-size: 1.6rem;
                    }

                    .descriptionOfBonus {
                        width: 100%;
                        font-size: 1.3rem;
                        margin-left: 3vw;
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_get {
        padding: 5vh 0;

        .wrapper_name {
            font-size: 2.7rem;
            margin-bottom: 2vh;
            font-family: "Barlow-Bold";
        }
        .get {
            width: 80%;
            flex-direction: column;

            .description_section {
                width: 100%;
                height: auto;
                gap: 3vh;

                .bonus {
                    width: 100%;
                    height: auto;
                    .nameOfBonus {
                        width: 100%;
                        font-size: 1.6rem;
                    }

                    .descriptionOfBonus {
                        width: 100%;
                        font-size: 1.3rem;
                        margin-left: 3vw;
                    }
                }
            }
        }
    }
}
