.wrapper_general_product {
    width: 100%;
    height: auto;
    background: rgb(255, 245, 232);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7vh 0;

    .general_section {
        width: 80%;
        // height: 60vh;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .left_side {
            display: flex;
            background: rgb(255, 245, 232); //rgb(255, 198, 132)
            border-radius: 20px;
            border: 1px solid black;
            width: 80%;
            height: auto;
            gap: 1vw;
            padding: 4vh 2vw;

            .left_small_section {
                display: flex;
                flex-direction: column;
                width: 60%;
                height: auto;
                gap: 3vh;

                .product_name {
                    padding: 1vh 1vw;
                    font-size: 2.5em;
                    font-weight: 600;
                }

                .short_description {
                    display: flex;
                    font-size: 1.2em;
                    padding: 0 1vw;
                }

                .section_one {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5vh;
                    font-size: 1em;
                }

                .section_one {
                    span::before {
                        content: "\2022";
                        padding: 0 1vw;
                    }
                }

                .order_now {
                    border: 1.5px solid black;
                    border-radius: 10px;
                    padding: 1vh 2vw;
                    background: rgba(255, 244, 185, 0.522);
                    font-size: 1.1em;
                }

                .cart_section {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;

                    .btn{
                        padding: .5rem 10vw;
                    }

                }
            }

            .right_img_side {
                // min-width: 140px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .img {
                    width: 500px;
                    position: absolute;
                    height: auto;
                    border-radius: 15px;
                    box-shadow: 0 0 15px 1px rgb(0,0,0, 0.5);
                    transition: 0.3s ease-in-out;
                    top: -1.5vh;
                    left: 5vw;
                }
            }
        }
    }
}
