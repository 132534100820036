.wrapper_top {
    background-image: url("../../../img/bac3.png");
    // background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.gradient {
    width: 100%;
    height: inherit;
    background: linear-gradient(to top, #040404b5, rgba(145, 145, 145, 0.022));
    z-index: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    transition: height 0.3s ease;
    z-index: 1;

    // .navigation_wrapper {
    //     width: 100%;
    //     height: 45px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     padding: 45px 0;
    //     transition: padding 0.3s ease;
    .navigation_wrapper {
        width: 100%;
        // height: 45px;
        height: 3vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5vh 0;
        transition: padding 0.3s ease;

        .nav_bar {
            width: 34vw;
            display: flex;
            justify-content: left;
            margin-left: -4vw;

            &:hover{

            }

            .wrapper_close_button{
                display: none;
            }

            .link {
                
                width: 10vw;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: white;
                font-size: clamp(1.0625rem, 0.5081rem + 0.8065vw, 1.375rem);

                
                span{
                    display: inline-block;
                    position: relative;

                    &:hover{
                        // text-decoration: underline;
                        cursor: pointer;
                    }
    
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -4px;
                        width: 100%;
                        height: 2.5px;
                        background: white;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform 0.4s ease;
                    }
            
                    &:hover::after{
                        transform: scaleX(1);
                    }
                }

            }
    
            .burger_menu_social{
                display: none;
            }

            .privacy_policy_section{
                display: none;
            }
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30vw;
            height: auto;
            color: red;
            
            .iconLogo {
                width: 100px;
                height: 100px;
                border-radius: 7px;
                padding-top: 2vh;
            }

            .item{
                display: none;
            }
        }
        .logo.shrink{
            color: black;

            .iconLogo{
                display: none;
            }

            .item{
                font-size: 2em;
            }

            .item1 {
                display: block;
                animation-name: wiggle;
                animation-duration: 0.7s;
                animation-fill-mode: forwards;
                animation-iteration-count: 0.7s;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }

            .item2 {
                display: block;    
                color: rgba(234, 39, 39, 0.85);
                animation-name: wiggle2;
                animation-duration: 0.9s;
                animation-fill-mode: forwards;
                animation-iteration-count: 0.7s;
                animation-timing-function: cubic-bezier(0.19, -0.17, 0.57, 1.26);
            }
        }
    

        
    }

    // .navigation_wrapper.shrink {
    //     padding: 4.5vh 0;
    //     background: rgb(255, 245, 232);
    //     box-shadow: 1px 1px 15px 1px rgb(45, 45, 45);
    // }
    .navigation_wrapper.shrink {
        padding: 0 0;
        height: 7vh;
        background: rgb(255, 245, 232);
        box-shadow: 1px 1px 15px 1px rgb(45, 45, 45);
    }

    .nav_bar.shrink {
        .link {
            color: black;
            height: 7vh;
            padding: 0 .4vw;

            span{

                &::after{
                    position: static;
                }
            }
        }
        .link:hover {
            color: white;
            background: rgba(234, 39, 39, 0.85);
           
        }
    }

    .link:active,
    .link.selected {
        text-decoration: underline;
    }

    .shopping_basket {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 30vw;
        height: auto;
        color: white;
        position: relative;

        .iconBasket{
            width: 45px;
            height: 45px;
        }
    }

    .shopping_basket.shrink{
        color: black;
    }

    .iconBasket:hover {
        cursor: pointer;
    }
}

.wrapper_middle {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 90%;
}

.information_about_company {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
}

.company_name {
    width: 50vw;
    margin: 0 auto;
    padding: 1vh 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    font-weight: 600;
    text-shadow: 3px 3px 0 black;
    text-align: center;
}

.description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40vw;
    padding: 1vh 0;
    margin: 0 auto;
    color: white;
    font-size: 1.5em;

    span {
        background-color: #00000070;
        // border: 2px rgb(166, 166, 166) solid;
        border-radius: 10px;
        padding: 1.5vh 1vw;
        color: #ffffff;
        text-align: center;
        line-height: 35px;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    width: 30vw;
    margin: 2vh auto;
    color: white;

    .button_buy {
        font-size: 1.3em;
        font-weight: 600;
        color: rgb(255, 79, 0); //rgb(49, 0, 227)
        background: white;
        border: 3px solid white;
        border-radius: 7px;
    }

    .button {
        transition: 0.5s;
        max-width: 100%;
        width: 12vw;
        height: 50px;
    }

    .button_buy:hover {
        background: rgb(255, 79, 0); //rgb(107, 69, 243)
        border-color: rgb(255, 79, 0);
        color: white;
    }

    .button:hover {
        width: 14vw;
        cursor: pointer;
    }

    .learn_more {
        font-size: 1.3em;
        font-weight: 600;
        color: white;
        background: none;
        border: 3px solid white;
        border-radius: 7px;
    }
    .learn_more:hover {
        border: 3px solid rgb(255, 79, 0);
        color: rgb(255, 79, 0);
    }
}
.burger-menu {
    display: none; 
}


.cart-icon-badge {
    position: absolute;
    top: 3vh;
    background-color: red;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}