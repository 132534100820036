@media (max-width: 480px) {
    .wrapper_footer {
        padding: 3vh 0;
        padding-bottom: 0;

        .footer {
            width: 90%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 5vh;

            .first_section {
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                flex-grow: 0;
                width: 100%;
                margin-top: 2vh;

                .logo_name {
                    height: 120px;
                    width: 100%;

                    .item1 {
                        width: 110px;
                        height: auto;
                    }
                }
                .social_section{
                    width: 100%;
                    height: 100px;
                    justify-content: center;
                    align-items: baseline;

                    .item {
                        width: 40px;
                        height: auto;
                    }
                }

                .button_section {
                    width: 100%;
                    height: 100px;
                    align-items: flex-start;
                    justify-content: flex-end;

                    .button_follow {
                        padding: 0 3vw;
                        font-size: 1.1rem;
                        font-weight: bold;
                    }
                }
            }

            .second_section {
                // height: auto;
                // gap: 1vh;
                // order: 1;
                display: none;

                .item_gen {
                    font-weight: 700;
                    font-size: 1.3rem;
                }

                .item {
                    font-size: 1rem;
                }
            }

            .third_section {
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: flex-start;
                gap: 1vh;

                .input_email_section {
                    width: 100%;
                    margin-bottom: 2vh;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1.5vh;

                    .input_email {
                        width: 180px;
                        height: 20px;
                        padding: 1vh 1vh;
                    }
                    .item_gen {
                        width: 100%;
                        font-size: 1.3rem;
                        font-weight: 700;
                    }

                    .item {
                        font-size: 1.1rem;
                        font-weight: 600;
                        width: 100%;
                    }
                    .submit_email{
                        width: 90px;
                        height: 30px;
                        font-size: .9rem;
                        font-weight: 600;
                    }
                }
            }
        }
        .privacy_policy_section {
            margin: 0 0;
            align-items: center;
            justify-content: center;

            .table_privacy {
               
                padding: 0 0;
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    font-size: 0.8rem;
                }

                li:before {
                    padding: 0 0.2rem 0 0.6rem;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .wrapper_footer {
        padding: 3vh 0;
        padding-bottom: 0;

        .footer {
            width: 90%;
            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            gap: 3vh;

            .first_section {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                flex-grow: 0;
                width: 100%;

                .logo_name {
                    height: 140px;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 2vh;
                    margin-left: 2.5vw;
                   
                    .item1 {
                        width: 140px;
                        height: auto;
                    }
                }
                .social_section{
                    width: 100%;
                    height: 60px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    
                    .item {
                        width: 50px;
                        height: auto;
                    }

                }
                .button_section {
                    width: 100%;

                    height: 100px;
                    align-items: flex-start;
                    justify-content: flex-end;

                    .button_follow {
                        height: 50px;
                        padding: 0 3vw;
                        font-size: 1.3rem;
                        font-weight: bold;
                    }
                }
            }

            .second_section {
                width: 100%;
                height: auto;
                gap: 1vh;
                order: 1;
                // display: none;

                .item_gen {
                    font-weight: 700;
                    font-size: 1.5rem;
                }

                .item {
                    font-size: 1.2rem;
                }
            }

            .third_section {
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: flex-start;
                gap: 1vh;

                .input_email_section {
                    width: 65%;
                    margin-bottom: 2vh;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1vh;

                    .input_email {
                        width: 180px;
                        height: 15px;
                        padding: 1vh 1vh;
                    }
                    .item_gen {
                        width: 100%;
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    .item {
                        font-size: 1.2rem;
                        width: 100%;
                    }
                    .submit_email{
                        width: 90px;
                        height: 30px;
                        font-size: .9rem;
                        font-weight: 600;
                    }
                }
                .social_section {
                    width: 95%;
                    justify-content: flex-start;
                    align-items: center;

                }
            }
        }
        .privacy_policy_section {
            margin: 0 0;
            align-items: center;
            justify-content: center;

            .table_privacy {
                width: 95%;
                padding: 0 0;
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    font-size: 1.1rem;
                }

                li:before {
                    padding: 0 0.2rem 0 0.6rem;
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .wrapper_footer {
        padding: 3vh 0;
        padding-bottom: 0;

        .footer {
            width: 100%;
            height: auto;
            margin-bottom: 2vh;
            justify-content: flex-start;
            flex-direction: row;
            gap: 3vh;

            .first_section {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 0;
                width: 100%;

                .logo_name {

                    .item1 {
                        width: 140px;
                        height: auto;
                    }
                }

                .social_section {
                    width: 95%;
                    justify-content: center;
                    align-items: center;

                    .item {
                        width: 40px;
                        height: auto;
                    }
                }

                .button_section {
                    width: 100%;

                    height: 100px;
                    align-items: flex-start;
                    justify-content: center;

                    .button_follow {
                        width: 25vw;
                        height: 40px;
                        padding: 0 3vw;
                        font-size: 1.2rem;
                        font-weight: bold;
                    }
                }
            }

            .second_section {
                width: 100%;
                height: auto;
                gap: 1vh;
                order: 1;
                // display: none;

                .item_gen {
                    font-weight: 700;
                    font-size: 1.5rem;
                }

                .item {
                    font-size: 1.2rem;
                }
            }

            .third_section {
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: flex-start;
                gap: 1vh;

                .input_email_section {
                    width: 65%;
                    margin-bottom: 2vh;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1vh;

                    .input_email {
                        width: 180px;
                        height: 15px;
                        padding: 1vh 1vh;
                    }
                    .item_gen {
                        width: 100%;
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    .item {
                        font-size: 1.2rem;
                        width: 100%;
                    }
                    .submit_email{
                        width: 90px;
                        height: 30px;
                        font-size: .9rem;
                        font-weight: 600;
                    }
                    
                }
                
            }
        }
        .privacy_policy_section {
            margin: 0 0;
            align-items: center;
            justify-content: center;

            .table_privacy {
                width: 95%;
                padding: 0 0;
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    font-size: 1.1rem;
                }

                li:before {
                    padding: 0 0.2rem 0 0.6rem;
                }
            }
        }
    }
}
